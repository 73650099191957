import { InvestPage } from './Invest'

export const TOOLBARS = {
  invest_page: {
    name: 'Invest',
    path: '/',
    exact: true,
    element: InvestPage
  }
}

export const PAGES = {
  ...TOOLBARS
}
