import React from 'react'
import { Container, Grid } from '@mui/material'
import { InvestForm } from '../components/invest/forms/Form'
import './styles/invest.scss'
import { DialogCustom } from '../components/commons/Dialog'
import { ErrorKey, ERRORS } from '../errors'
import { useSelector } from 'react-redux'
import { RootState } from '../redux'

export const InvestPage = (props: any) => {
  const [title, setTitle] = React.useState<string>('')
  const [description, setDescription] = React.useState<string>('')
  const [openDialog, setOpenDialog] = React.useState<boolean>(false)
  const walletState = useSelector((state: RootState) => state.wallet)
  const account = walletState.account

  const onError = (key: ErrorKey, _title?: string, _description?: string) => {
    setTitle(_title || ERRORS[key].title)
    setDescription(_description || ERRORS[key].description)
    setOpenDialog(true)
  }

  return (
    <Container className="container">
      <section className="invest_section">
        {account && (
          <Grid container justifyContent="flex-end">
            <div className="invest__account">
              {`${account?.substring(0, 12)}...${account?.substring(account.length - 5, account.length)}`} Connected
            </div>
          </Grid>
        )}
        <Grid container justifyContent="center" className="invest__header">
          <h1>
            576 DAO <em>&#x25A0;</em>
          </h1>
        </Grid>
        <InvestForm
          on_error={data => {
            onError(data.key, data.title, data.description)
          }}
        />
      </section>
      <DialogCustom
        cancel_button_name="Dismiss"
        title={title}
        description={description}
        open={openDialog}
        handle_close={() => setOpenDialog(false)}
      />
    </Container>
  )
}
