import React, { useEffect, useState } from 'react'
import { TextField, Box, Button, Fab, CircularProgress, Link } from '@mui/material'
import { ethers } from 'ethers'
import { DAO_TOKEN_ABI } from '../../../contracts/governance'
import './form.scss'
import { ErrorKey } from '../../../errors'
import { RootState } from '../../../redux'
import { useDispatch, useSelector } from 'react-redux'
import { setAccount, setProvider } from '../../../redux/actions/wallet.actions'
import { APP_ENVS } from '../../../configs/env'
import axios from 'axios'
import { find } from 'lodash'

type InvestFormProps = {
  on_error: (data: { key: ErrorKey; title?: string; description?: string }) => void
}

export const InvestForm = (props: InvestFormProps) => {
  const { ethereum } = window
  const dispatch = useDispatch()

  const [ethBalance, setEthBalance] = useState<string>('0')
  const [loadedBalance, setLoadedBalance] = useState<boolean>(false)

  const [contract, setContract] = useState<ethers.Contract | null>(null)
  const [totalSupply] = useState<number>(1000000)
  const [totalSold, setTotalSold] = useState<number>(0)
  const [tokensPerEther, setTokensPerEther] = useState<number>(1000)
  const [whitelistInfo, setWhitelistInfo] = useState({
    max_amount: 0,
    signature: '0x0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000'
  })

  const [ethAmount, setEthAmount] = useState<number>(5)

  const [txnLoading, setTxnLoading] = useState<boolean>(false)
  const [txnRecently, setTxnRecently] = useState<string | null>('')
  const walletState = useSelector((state: RootState) => state.wallet)
  const provider = walletState.provider
  const account = walletState.account

  useEffect(() => {
    if (ethereum) {
      ethereum.on('accountsChanged', (accounts: string[]) => {
        if (accounts.length) {
          dispatch(setAccount({ account: accounts[0] }))
        } else {
          dispatch(setAccount({ account: null }))
        }
      })

      ethereum.on('chainChanged', async (chainId: string) => {
        if (!isMainnet(Number(chainId))) {
          return
        }
        await setWalletState(new ethers.providers.Web3Provider(ethereum))
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ethereum, dispatch])

  useEffect(() => {
    if (!provider) return
    const signer = provider.getSigner()
    setContract(new ethers.Contract(APP_ENVS.DAO_TOKEN_CONTRACT_ADDRESS, DAO_TOKEN_ABI, signer))
  }, [provider])

  useEffect(() => {
    fetchDataOnChain()
  }, [])

  useEffect(() => {
    getAccountBalance()
    fetchWhitelist()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  const fetchDataOnChain = async () => {
    const url = APP_ENVS.ETHEREUM_PROVIDER_URL
    const defProvider = url ? ethers.getDefaultProvider(url) : ethers.getDefaultProvider()
    const defContract = new ethers.Contract(APP_ENVS.DAO_TOKEN_CONTRACT_ADDRESS, DAO_TOKEN_ABI, defProvider)
    try {
      const res = await Promise.all([defContract.totalSold(), defContract.tokensPerEther()])
      setTotalSold(Number(res[0]))
      setTokensPerEther(Number(res[1]))
    } catch (err) {
      console.log(err)
    }
  }

  const getAccountBalance = async () => {
    if (!account) return
    try {
      const provider = ethers.getDefaultProvider(APP_ENVS.ETHEREUM_PROVIDER_URL)
      const balance = await provider.getBalance(account)

      setEthBalance(String(balance))
      setLoadedBalance(true)
      if (balance.lte(ethers.utils.parseEther(String(ethAmount)))) {
        return setEthAmount(Math.floor(Number(ethers.utils.formatEther(String(balance)))))
      }
    } catch {}
  }

  const fetchWhitelist = async () => {
    try {
      const res = await axios.get(APP_ENVS.DAO_576_TOKEN_WHITELIST_URL)
      const whitelistData = find(res.data, el => {
        return String(el.address).toLowerCase() === String(account).toLowerCase()
      })
      if (whitelistData) {
        setWhitelistInfo({
          max_amount: whitelistData?.max_amount,
          signature: whitelistData?.signature
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  const connectWallet = async () => {
    if (provider) return
    if (ethereum) {
      try {
        await ethereum.request({ method: 'eth_requestAccounts' })
        const newProvider = new ethers.providers.Web3Provider(ethereum)
        const { chainId } = await newProvider.getNetwork()
        if (!isMainnet(chainId)) {
          return
        }
        await setWalletState(newProvider)
      } catch {}
      return
    }
  }

  const setWalletState = async (ethProvider: ethers.providers.Web3Provider) => {
    dispatch(setProvider({ provider: ethProvider }))
    if (!walletState.account) {
      const ethAccount = await ethProvider.getSigner().getAddress()
      dispatch(setAccount({ account: ethAccount }))
    }
  }

  const isMainnet = (chainId: number): boolean => {
    if (chainId !== APP_ENVS.ETHEREUM_CHAIN_ID) {
      dispatch(setProvider({ provider: null }))
      dispatch(setAccount({ account: null }))
      props.on_error({ key: 'MAINNET_ERROR_CONNECTED' })
      return false
    }
    return true
  }

  const addTokenToMetamask = async () => {
    if (!provider) await connectWallet()
    if (ethereum) {
      try {
        await ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20',
            options: {
              address: APP_ENVS.DAO_TOKEN_CONTRACT_ADDRESS,
              symbol: '576',
              decimals: 0,
              image: `${window.location.origin}/576-favicon.png`
            }
          }
        })
      } catch (err) {
        console.log(err)
      }
    }
  }

  const claimTokens = async () => {
    if (!contract) {
      return props.on_error({ key: 'MAINNET_ERROR_CONNECTED' })
    }
    if (!loadedBalance) {
      return props.on_error({ key: 'CUSTOM', title: 'Loading ETH balance', description: 'Loading your ETH balance, please wait...' })
    }
    try {
      setTxnLoading(true)
      const amount = Number(ethers.utils.parseEther(String(ethAmount)).div(ethers.utils.parseEther('1').div(tokensPerEther)))
      if (ethers.BigNumber.from(ethBalance).lte(ethers.utils.parseEther(String(ethAmount)))) {
        return props.on_error({ key: 'CUSTOM', title: 'Insufficient funds', description: 'Insufficient funds' })
      }
      if (isNaN(amount) || amount === 0) {
        return props.on_error({ key: 'CUSTOM', title: 'Amount Invalid', description: 'Amount must be greater than 0' })
      }
      const gasEstimate = await contract.estimateGas.purchase(whitelistInfo.max_amount, whitelistInfo.signature, amount, {
        value: ethers.utils.parseEther(String(ethAmount))
      })
      const txn = await (
        await contract.purchase(whitelistInfo.max_amount, whitelistInfo.signature, amount, {
          gasLimit: gasEstimate.mul(150).div(100),
          value: ethers.utils.parseEther(String(ethAmount))
        })
      ).wait()
      setTxnRecently(txn.transactionHash)
    } catch (err: any) {
      props.on_error({ key: 'CUSTOM', description: err.error ? err.error.message : err.message || 'Transaction cancelled by user' })
    } finally {
      setTxnLoading(false)
    }
  }

  const onEthValueChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isNaN(Number(event.target.value))) return
    if (ethers.BigNumber.from(ethBalance).lte(ethers.utils.parseEther(String(Number(event.target.value))))) {
      return setEthAmount(Math.floor(Number(ethers.utils.formatEther(ethBalance))))
    }
    setEthAmount(Math.round(Number(event.target.value)))
  }

  return (
    <Box className="box">
      <div className="wrapper">
        <label>Buy $576 DAO Tokens</label>

        <TextField
          label="ETH Amount"
          className="input-eth"
          id="input-ethereum-value"
          placeholder="1"
          size="small"
          type="text"
          onChange={onEthValueChanged}
          value={ethAmount}
        />

        <div className="info-section">
          <div className="conversion">
            {ethAmount} ETH ={' '}
            {Number(ethers.utils.parseEther(String(ethAmount)).div(ethers.utils.parseEther('1').div(tokensPerEther))).toLocaleString()} DAO Tokens
          </div>
        </div>

        {!provider ? (
          <Button className="action-button" color="primary" variant="contained" onClick={connectWallet}>
            Connect Wallet
          </Button>
        ) : (
          <Button className="action-button" color="primary" variant="contained" onClick={claimTokens}>
            {txnLoading && <CircularProgress className="txn-loading" size={24} />}
            {!txnLoading && 'Buy Tokens'}
          </Button>
        )}

        {txnRecently && (
          <div className="conversion">
            Transaction Submitted, TXN Hash: &nbsp;
            <Link href={`https://etherscan.io/tx/${txnRecently}`} underline="none" color="primary">
              {txnRecently}
            </Link>
          </div>
        )}

        <div className="conversion">
          Sold: {totalSold.toLocaleString()} / {totalSupply.toLocaleString()}
        </div>
      </div>
      <div className="box__footer">
        <Fab variant="extended" color="primary" className="float-button" onClick={addTokenToMetamask}>
          Add $576 to MetaMask
        </Fab>
      </div>
    </Box>
  )
}
