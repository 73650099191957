import { combineReducers, createStore } from 'redux'
import { wallet } from './reducer/wallet.reducer'

export const rootReducer = combineReducers({
  wallet
})

export type RootState = ReturnType<typeof rootReducer>

export const store = createStore(rootReducer)
