import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import reportWebVitals from './reportWebVitals'
import { map } from 'lodash'
import { createTheme, ThemeProvider, CssBaseline } from '@mui/material'
import { store } from './redux'
import { PAGES } from './pages'

import './assets/index.scss'

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#f6d34a',
      contrastText: '#111'
    }
  },
  typography: {
    fontFamily: ['Montserrat', 'sans-serif'].join(',')
  }
})

const App = (props: any) => {
  return (
    <Router>
      <div id="app-wrapper">
        <div className="app-content">
          <Routes>
            {map(PAGES, (page, key) => (
              <Route path={page.path} key={key} element={page.element(props)} />
            ))}
          </Routes>
        </div>
      </div>
    </Router>
  )
}

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
