import { SET_ACCOUNT, SET_PROVIDER } from '../constants/wallet.action.types'
import { ProviderAction, ProviderPayload, WalletAction, WalletPayload } from '../types'

type MixinAction = ProviderAction | WalletAction
type MixinReducers = ProviderPayload & WalletPayload

export const wallet = (state: any, action: MixinAction): MixinReducers => {
  state = state || {}
  switch (action.type) {
    case SET_PROVIDER:
      return {
        ...state,
        provider: action.payload.provider
      }
    case SET_ACCOUNT:
      return {
        ...state,
        account: action.payload.account
      }

    default:
      return state
  }
}
