export type ErrorKey = 'CUSTOM' | 'METAMASK_NOT_CONNECTED' | 'MAINNET_ERROR_CONNECTED'

type ErrorType = {
  [key in ErrorKey]: {
    title: string
    description: string
  }
}

export const ERRORS: ErrorType = {
  METAMASK_NOT_CONNECTED: {
    title: 'MetaMask not connected',
    description:
      'Please connect your blockchain wallet. If you have issues with accessing, please download and install <a href="https://metamask.io/download.html" style="color: #00feff;">MetaMask</a>'
  },
  MAINNET_ERROR_CONNECTED: {
    title: 'Incorrect blockchain',
    description: 'Please switch to Ethereum mainnet to purchase tokens.'
  },
  CUSTOM: {
    title: 'Unknown error',
    description: 'Blockchain Error'
  }
}
