import { SET_PROVIDER, SET_ACCOUNT } from '../constants/wallet.action.types'
import { ProviderPayload, WalletPayload } from '../types'

export const setProvider = (payload: ProviderPayload) => {
  return {
    type: SET_PROVIDER,
    payload
  }
}

export const setAccount = (payload: WalletPayload) => {
  return {
    type: SET_ACCOUNT,
    payload
  }
}
