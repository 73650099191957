import React from 'react'
import { Button, Dialog, DialogContent, DialogActions, DialogTitle } from '@mui/material'

type DialogProps = {
  open: boolean
  handle_close: () => void
  title: string
  description: string
  cancel_button_name?: string
}

export const DialogCustom = (props: DialogProps) => {
  const title = props.title
  const description = props.description
  const cancelButton = props.cancel_button_name || 'Cancel'
  const handleClose = props.handle_close

  return (
    <Dialog open={props.open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent dangerouslySetInnerHTML={{ __html: description }}></DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          {cancelButton}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
